body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.App {
    font-family: "Chakra Petch", sans-serif;
    letter-spacing: 1.5px;
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 7s linear;
                animation: App-logo-spin infinite 7s linear;
        animation-direction: alternate-reverse;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    to {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    to {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
}
